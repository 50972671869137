import React from 'react';
import { Heading } from 'cj-common-components';
import { getConfigSection } from '../common/utils';
import { USER_ROLE } from '../common/constants';
import { withAuth } from '../auth/withAuth';
import { ModalError } from './common-components/ModalWindow';

const uiTexts = require('../resources/uiTexts.json');

class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessageAcknowledged: false,
    };
  }

  render() {
    const welcomeText = getConfigSection(uiTexts, 'home.welcome');
    const { errorMessageAcknowledged } = this.state;
    const { userRole } = this.props;
    return (
      <div className="o-layout--center">
        <Heading headerType={3}>{welcomeText.header}</Heading>
        {userRole !== USER_ROLE.ROLE_NONE && <Heading headerType={6}>{welcomeText.subheader}</Heading>}
        {userRole === USER_ROLE.ROLE_NONE && !errorMessageAcknowledged && this.renderErrorDialog()}
      </div>
    );
  }

  renderErrorDialog() {
    return (
      <ModalError
        errorKey="home.noRole"
        onConfirm={() => {
          this.setState({
            errorMessageAcknowledged: true,
          });
        }}
      />
    );
  }
}

export default withAuth(Home);

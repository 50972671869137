import React from 'react';
import * as Yup from 'yup';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import commonPropTypes from '../../../common/common-prop-types';
import { FILE_TYPES, PAYMENT_OPTION_PROCESSING_TYPE } from '../../../common/constants';
import { checkFileSize, checkFileType, getConfigSection } from '../../../common/utils';
import BaseFormFields from '../../common-components/BaseFormFields';
import {
  addDefaultSelectOptionToSelectValues,
  mapValuesArrayToSelectComponentValues,
} from '../../common-components/common-functions';
import InfoIconText from '../../common-components/InfoIconText';
import { ModalConfirmOperation } from '../../common-components/ModalWindow';

const uiTexts = require('../../../resources/uiTexts.json');

const errorsTexts = require('../../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static validationSchema(isUpdateOperation) {
    return Yup.object().shape({
      displayName: Yup.string().required(error.mandatoryField),
      smartPayCode: Yup.string().required(error.mandatoryField),
      providerCode: Yup.string().required(error.mandatoryField),
      processingType: Yup.string()
        .required(error.mandatoryField)
        .notOneOf(['None']),
      addToCreditCardGroup: Yup.boolean().required(error.mandatoryField),
      mobileSdkEnabled: Yup.boolean().required(error.mandatoryField),
      storableSPO: Yup.boolean().required(error.mandatoryField),
      paymentFormAsButton: Yup.boolean().required(error.mandatoryField),
      logoBase64: isUpdateOperation
        ? Yup.mixed()
            .notRequired()
            .test('Image type', 'Image should be of type jpeg or png', file =>
              checkFileType(file, [FILE_TYPES.JPG, FILE_TYPES.JPEG, FILE_TYPES.PNG]),
            )
            .test('Image size', 'Image is too large', file => checkFileSize(file, 0.2))
        : Yup.mixed()
            .test('Image type', 'Image should be of type jpeg or png', file =>
              checkFileType(file, [FILE_TYPES.JPG, FILE_TYPES.JPEG, FILE_TYPES.PNG]),
            )
            .test('Image size', 'Image is too large', file => checkFileSize(file, 0.2)),
    });
  }

  static propTypes = {
    ...commonPropTypes.formFields,
  };

  constructor(props) {
    super(props);

    const { authToken } = this.props;

    this.state = {
      currencyList: [],
      showWarning: false,
      isCitStorable: false,
      isMitStorable: false,
      isStoredCitPaymentsAllowed: false,
      isStoredMitPaymentsAllowed: false,
      paymentOptionLogo: false,
    };

    this.chooseFileRef = React.createRef();
    this.updateRefDataState = this.updateRefDataState.bind(this);
    this.handleCitStorableChange = this.handleStoreOptionChange.bind(this);
    this.cancelUpdateDataItem = this.cancelUpdateDataItem.bind(this)
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { textsKey, formikProps, authToken, isFormEditable, dataStatusLoadCallback, customProps } = this.props;
    const { paymentOptionLogoUrl } = customProps;
    const fieldsTextKey = `${textsKey}.form.fields`;

    const texts = this.props.uiTexts || uiTexts;
    const selectAnOptionText = getConfigSection(texts, 'common.editForm.comboSelectAnOption');
    const {
      renderGenericInput,
      renderGenericMultiSelect,
      renderGenericSelect,
      renderWarningUpload,
      renderGenericCheckbox,
      renderUpload,
    } = BaseFormFields;

    const {
      currencyList,
      showWarning,
      isCitStorable,
      isMitStorable,
      isStoredCitPaymentsAllowed,
      isStoredMitPaymentsAllowed,
      paymentOptionLogo,
    } = this.state;

    this.adaptFields(formikProps);

    const fieldsRenderConfig = [
      {
        id: 'displayName',
        useRequiredLabel: true,
        function: renderGenericInput('text', isFormEditable),
      },
      {
        id: 'smartPayCode',
        useRequiredLabel: true,
        function: renderGenericInput('text', isFormEditable),
      },
      {
        id: 'providerCode',
        useRequiredLabel: true,
        function: renderGenericInput('text', isFormEditable),
      },
      {
        id: 'processingType',
        function: renderGenericSelect(
          true,
          addDefaultSelectOptionToSelectValues(
            PAYMENT_OPTION_PROCESSING_TYPE.map(item => ({
              value: item.value,
              label: item.name,
            })),
            'None',
            'None',
          ),
        ),
      },
      {
        id: 'supportedCurrencies',
        function: renderGenericMultiSelect(
          isFormEditable,
          selectAnOptionText,
          mapValuesArrayToSelectComponentValues(currencyList, 'name', 'isoCode'),
          formikProps,
        ),
      },
      {
        id: 'addToCreditCardGroup',
        function: renderGenericCheckbox(isFormEditable, ''),
      },
      {
        id: 'mobileSdkEnabled',
        function: renderGenericCheckbox(isFormEditable, ''),
      },
      {
        id: 'isGuest',
        labelAddOn: (
          <InfoIconText text={getConfigSection(texts, `${fieldsTextKey}.isGuestInfo`)} testId="isGuest-info-text" />
        ),
        function: renderGenericCheckbox(isFormEditable, ''),
      },
      {
        id: 'isCitStorable',
        labelAddOn: (
          <InfoIconText
            text={getConfigSection(texts, `${fieldsTextKey}.isCitStorableInfo`)}
            testId="isCitStorable-info-text"
          />
        ),
        function: renderGenericCheckbox(isFormEditable, '', {
          onChange: event => this.handleStoreOptionChange(event, 'isCitStorable'),
        }),
      },
      {
        id: 'isMitStorable',
        labelAddOn: (
          <InfoIconText
            text={getConfigSection(texts, `${fieldsTextKey}.isMitStorableInfo`)}
            testId="isMitStorable-info-text"
          />
        ),
        function: renderGenericCheckbox(isFormEditable, '', {
          onChange: event => this.handleStoreOptionChange(event, 'isMitStorable'),
        }),
      },
      {
        id: 'isStoredCitPaymentsAllowed',
        labelAddOn: (
          <InfoIconText
            text={getConfigSection(texts, `${fieldsTextKey}.isStoredCitPaymentsAllowedInfo`)}
            testId="isStoredCitPayments-info-text"
          />
        ),
        function: renderGenericCheckbox(isFormEditable, '', {
          onChange: event => this.handleStoreOptionChange(event, 'isStoredCitPaymentsAllowed'),
        }),
      },
      {
        id: 'isStoredMitPaymentsAllowed',
        labelAddOn: (
          <InfoIconText
            text={getConfigSection(texts, `${fieldsTextKey}.isStoredMitPaymentsAllowedInfo`)}
            testId="isStoredMitPayments-info-text"
          />
        ),
        function: renderGenericCheckbox(isFormEditable, '', {
          onChange: event => this.handleStoreOptionChange(event, 'isStoredMitPaymentsAllowed'),
        }),
      },
      {
        id: 'paymentFormAsButton',
        function: renderGenericCheckbox(isFormEditable, ''),
      },
      {
        id: 'logoBase64',
        useRequiredLabel: true,
        labelAddOn: (
          <InfoIconText text={getConfigSection(texts, `${fieldsTextKey}.logoBase64`)} testId="logoBase64-info-text" />
        ),
        function: paymentOptionLogoUrl
          ? renderWarningUpload(
              formikProps,
              isFormEditable,
              showWarning,
              this.setState.bind(this),
              this.chooseFileRef,
              paymentOptionLogoUrl,
              texts.common.uploadedLogo,
            )
          : renderUpload(
              formikProps,
              isFormEditable,
              this.chooseFileRef,
              paymentOptionLogoUrl,
              texts.common.uploadedLogo,
            ),
      },
    ];

    return (
      <>
        {paymentOptionLogo && showWarning && <>{this.renderWarningDialog()}</>}
        {isCitStorable && showWarning && <>{this.renderWarningStoreOptions('isCitStorable')}</>}
        {isMitStorable && showWarning && <>{this.renderWarningStoreOptions('isMitStorable')}</>}
        {isStoredCitPaymentsAllowed && showWarning && (
          <>{this.renderWarningStoreOptions('isStoredCitPaymentsAllowed')}</>
        )}
        {isStoredMitPaymentsAllowed && showWarning && (
          <>{this.renderWarningStoreOptions('isStoredMitPaymentsAllowed')}</>
        )}
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          refDataLoadFunctions={[this.configurationDataApi.getCurrencies]}
          refDataLoadCallback={this.updateRefDataState}
          dataStatusLoadCallback={dataStatusLoadCallback}
          testIdPrefix="upcf-payment-option-form"
        />
      </>
    );
  }

  handleStoreOptionChange(event, propName) {
    const { formikProps } = this.props;
    const { setFieldValue } = formikProps;
    const isChecked = event.target.checked;

    setFieldValue(propName, isChecked);

    if (isChecked) {
      this.setState({ [propName]: false, showWarning: false });
    } else {
      this.setState({ [propName]: true, showWarning: true });
    }
  }

  renderWarningDialog() {
    const texts = this.props.uiTexts || uiTexts;

    const warningTexts = getConfigSection(texts, 'common.table.dialogs.warning');
    const { header, buttonOk, buttonCancel } = warningTexts;
    const { textsKey } = this.props;
    const message = getConfigSection(texts, `${textsKey}.form.fields.changePaymentOptionLogoWarning`);

    return (
      <>
        <ModalConfirmOperation
          title={header}
          buttonConfirmText={buttonOk}
          onConfirm={() => {
            this.chooseFileRef.current.click();
            this.setState({ paymentOptionLogo: false, showWarning: false });
          }}
          buttonCancelText={buttonCancel}
          onCancel={() => this.setState({ paymentOptionLogo: false, showWarning: false })}
          onClose={() => this.setState({ paymentOptionLogo: false, showWarning: false })}
          message={message}
        />
      </>
    );
  }

  setShowWarning(value) {
    this.setState({ showWarning: value });
  }

  updateRefDataState(refDataArray) {
    this.setState({
      currencyList: refDataArray[0],
    });
  }

  adaptFields(formikProps) {
    if (typeof formikProps.values.supportedCurrencies === 'string') {
      if (formikProps.values.supportedCurrencies.includes(', ')) {
        formikProps.values.supportedCurrencies = formikProps.values.supportedCurrencies.split(', ');
      } else {
        formikProps.values.supportedCurrencies = [formikProps.values.supportedCurrencies];
      }
    }

    if (typeof formikProps.values.storableSPO === 'string' || formikProps.values.storableSPO instanceof String) {
      formikProps.values.storableSPO = formikProps.values.storableSPO === 'true';
    }
  }

  renderWarningStoreOptions(propName) {
    const texts = this.props.uiTexts || uiTexts;

    const warningTexts = getConfigSection(texts, 'common.table.dialogs.warning');
    const { header, buttonOk, buttonCancel } = warningTexts;
    const { textsKey } = this.props;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const template = getConfigSection(texts, `${textsKey}.form.fields.changeStoreOptionsWarning`);
    const message = template.replace(/{settingName}/g, getConfigSection(texts, `${fieldsTextKey}.${propName}`));

    return (
      <>
        <ModalConfirmOperation
          title={header}
          buttonConfirmText={buttonOk}
          onConfirm={() => this.setState({ [propName]: false, showWarning: false })}
          buttonCancelText={buttonCancel}
          onCancel={() => this.cancelUpdateDataItem(propName)}
          onClose={() => this.setState({ [propName]: false, showWarning: false })}
          message={message}
          testId={`confirm-${propName}-remove-modal`}
        />
      </>
    );
  }

  cancelUpdateDataItem(propName) {
    const { formikProps } = this.props;
    const { setFieldValue } = formikProps;

    setFieldValue(propName, true);
    this.setState({ [propName]: false, showWarning: false });
  }
}

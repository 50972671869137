import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'cj-common-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ConfigurationDataApi from '../../api/ConfigurationData';
import { withAuth } from '../../auth/withAuth';
import MerchantEditForm from './MerchantEditForm';
import ChannelEditForm from './channels/ChannelEditForm';
import ChannelsTable from './channels/ChannelsTable';
import UsersAndGroups from './usersAndGroups/UsersAndGroups';
import { prepareMerchantDataTransformation } from './merchantDataUtils/prepareMerchantDataTransformation';
import { checkEditFormCanBeModified } from './merchantDataUtils/checkEditFormCanBeModified';
import IntegrationForm from './integration/Form';
import CultureForm from './culture/Form';
import { setIsLoading, setMerchantName } from '../../redux/breadCrumbsSlice';
import { RESOURCES_KEYS, ROUTE_KEYS, ROUTES } from '../../common/constants';
import BackButton from './merchantDataUtils/BackButton';
import commonPropTypes from '../../common/common-prop-types';
import { NotFoundResource } from '../common-components/NotFoundResource';
import UserEditForm from './usersAndGroups/users/UserEditForm';
import GroupsEditForm from './usersAndGroups/groups/GroupsEditForm';
import AuthenticationSettingsPage from './authentication/AuthenticationSettingsPage';
import MerchantHistoryPage from './history/MerchantHistoryPage';
import CorsDomains from './corsDomains/CorsDomains';
import HostedPaymentPage from './hostedPaymentPage/Form';
import NetworkTokenAndThreeDsManagementForm from './networkTokenAndThreeDsManagement/NetworkTokenAndThreeDsManagementForm';
import MerchantHistoryTable from './history/MerchantHistoryTable';
import NotificationsPage from './notifications/NotificationsPage';
import NotificationRetries from './notificationRetries/NotificationRetriesTable';
import NotificationSettingsForm from './notificationSettings/Form';
import AchSettingsForm from './achSettings/Form';
import WidgetConfigurationForm from './widgetConfiguration/Form';
import NotificationRetriesEditForm from './notificationRetries/EditForm';

class MerchantPage extends React.PureComponent {
  static propTypes = {
    authToken: commonPropTypes.authToken,
    dispatch: PropTypes.func.isRequired,
    ...commonPropTypes.router,
  };

  constructor(props) {
    super(props);
    this.state = {
      merchantId: null,
      merchantData: {},
      isFormEditable: false,
      isLoading: true,
    };
    const { authToken } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.updateMerchantData = this.updateMerchantData.bind(this);
    this.getMerchantData = this.getMerchantData.bind(this);
    this.lockUnlockMerchant = this.lockUnlockMerchant.bind(this);
  }

  componentDidMount() {
    this.initializeCompData();
  }

  componentDidUpdate() {
    if (this.props.match.params.merchantId !== this.state.merchantId) {
      this.initializeCompData();
    }
  }

  initializeCompData() {
    const {
      dispatch,
      match: {
        params: { merchantId },
      },
    } = this.props;

    if (merchantId === ROUTE_KEYS.creation) {
      dispatch(setIsLoading({ isLoading: false }));
      this.setState({
        isLoading: false,
        isFormEditable: true,
        merchantId,
        merchantData: {},
      });
    } else {
      dispatch(setIsLoading({ isLoading: true }));
      this.setState({ isLoading: true, merchantId });
      this.getMerchantData(merchantId);
    }
  }

  updateMerchantData(updatedData) {
    this.setPreparedData(updatedData);
  }

  getMerchantData(merchantId) {
    return this.configurationDataApi.getMerchantById(merchantId).then(result => {
      return this.setPreparedData(result);
    });
  }

  setPreparedData(merchantData) {
    const { authToken, dispatch } = this.props;
    const preparedMerchantData = prepareMerchantDataTransformation({
      merchant: merchantData,
      authToken,
    });

    const isFormEditable = checkEditFormCanBeModified({
      ...preparedMerchantData,
    });
    dispatch(setMerchantName({ merchantName: preparedMerchantData.name }));
    dispatch(setIsLoading({ isLoading: false }));
    this.setState({
      merchantData: preparedMerchantData,
      isLoading: false,
      isFormEditable,
    });
  }

  handleGoBack = location => {
    this.props.history.replace(location);
  };

  lockUnlockMerchant() {
    const { merchantData } = this.state;

    this.setState({ isLoading: true });
    this.configurationDataApi.lockUnlockMerchant(merchantData.id, !merchantData.isLocked).then(res => {
      this.setState({ isFormEditable: !res.isLocked });
      this.setState({ merchantData: { ...merchantData, isLocked: res.isLocked } });
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { authToken } = this.props;
    const { merchantId, merchantData, isLoading, isFormEditable } = this.state;
    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        <Switch>
          <Route
            exact
            key={merchantId}
            path={ROUTES.merchantPage}
            render={() => (
              <MerchantEditForm
                authToken={authToken}
                merchantData={merchantData}
                reloadMerchantData={() => {
                  return this.getMerchantData(merchantId);
                }}
                isFormEditable={isFormEditable}
                lockUnlockMerchant={this.lockUnlockMerchant}
              />
            )}
          />
          <Route
            exact
            path={ROUTES.merchantChannels}
            render={() => {
              return (
                <>
                  <ChannelsTable
                    authToken={authToken}
                    textsKey={RESOURCES_KEYS.merchants}
                    merchantData={{
                      ...merchantData,
                    }}
                    merchantId={merchantId}
                    isFormEditable={isFormEditable}
                    isAccessLevelApplicable={false}
                  />
                  <BackButton
                    textsKey={RESOURCES_KEYS.merchants}
                    onBack={this.handleGoBack}
                    backLocation={`/${RESOURCES_KEYS.merchants}/${merchantId}`}
                  />
                </>
              );
            }}
          />
          <Route
            path={ROUTES.channelPage}
            render={routeProps => {
              return (
                <ChannelEditForm
                  routeProps={routeProps}
                  authToken={authToken}
                  merchantData={merchantData}
                  textsKey={RESOURCES_KEYS.merchantChannels}
                  isFormEditable={isFormEditable}
                  onBack={this.handleGoBack}
                />
              );
            }}
          />
          <Route
            exact
            path={ROUTES.merchantUsersAndGroups}
            render={routeProps => (
              <>
                <UsersAndGroups
                  authToken={authToken}
                  routeProps={routeProps}
                  textsKey={RESOURCES_KEYS.merchants}
                  merchantData={{
                    ...merchantData,
                  }}
                  isFormEditable={isFormEditable}
                />
                <BackButton
                  textsKey={RESOURCES_KEYS.merchants}
                  onBack={this.handleGoBack}
                  backLocation={`/${RESOURCES_KEYS.merchants}/${merchantId}`}
                />
              </>
            )}
          />
          <Route
            exact
            path={ROUTES.merchantCreateGroupForm}
            render={routeProps => (
              <GroupsEditForm
                routeProps={routeProps}
                merchantData={{ ...merchantData }}
                authToken={authToken}
                isFormEditable={isFormEditable}
                textsKey={RESOURCES_KEYS.merchantGroupsWithMerchant}
                onBack={() =>
                  this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}/${ROUTE_KEYS.usersAndGroups}`)
                }
              />
            )}
          />
          <Route
            path={ROUTES.merchantEditUserForm}
            render={routeProps => (
              <UserEditForm
                routeProps={routeProps}
                merchantData={{ ...merchantData }}
                authToken={authToken}
                isFormEditable={isFormEditable}
                textsKey={RESOURCES_KEYS.merchantUsers}
                onBack={() =>
                  this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}/${ROUTE_KEYS.usersAndGroups}`)
                }
              />
            )}
          />
          <Route
            exact
            path={ROUTES.merchantIntegration}
            render={() => (
              <IntegrationForm
                authToken={authToken}
                textsKey={RESOURCES_KEYS.merchants}
                isFormEditable={isFormEditable}
                merchantData={{
                  ...merchantData,
                }}
                onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}`)}
                updateData={this.updateMerchantData}
              />
            )}
          />
          <Route
            exact
            path={ROUTES.notificationSettings}
            render={() => (
              <NotificationSettingsForm
                authToken={authToken}
                textsKey={RESOURCES_KEYS.notificationSettings}
                isFormEditable={isFormEditable}
                merchantId={merchantId}
                merchantData={merchantData}
                onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}`)}
              />
            )}
          />
          <Route
            exact
            path={ROUTES.merchantCulture}
            render={() => (
              <CultureForm
                authToken={authToken}
                textsKey={RESOURCES_KEYS.merchants}
                isFormEditable={isFormEditable}
                merchantData={{
                  ...merchantData,
                }}
                onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}`)}
              />
            )}
          />
          <Route
            exact
            path={ROUTES.authenticationSettings}
            render={() => {
              return (
                <>
                  <AuthenticationSettingsPage
                    onBack={this.handleGoBack}
                    authToken={authToken}
                    textsKey={RESOURCES_KEYS.merchants}
                    merchantData={{
                      ...merchantData,
                    }}
                    merchantId={merchantId}
                    isFormEditable={isFormEditable}
                    isAccessLevelApplicable={false}
                  />
                  <BackButton
                    textsKey={RESOURCES_KEYS.merchants}
                    onBack={this.handleGoBack}
                    backLocation={`/${RESOURCES_KEYS.merchants}/${merchantId}`}
                  />
                </>
              );
            }}
          />
          <Route
            exact
            path={ROUTES.merchantCorsDomains}
            render={() => {
              return (
                <>
                  <CorsDomains
                    textsKey={RESOURCES_KEYS.merchantCorsDomains}
                    authToken={authToken}
                    merchantId={merchantId}
                    isFormEditable={isFormEditable}
                    onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}`)}
                  />
                </>
              );
            }}
          />
          <Route
            exact
            path={ROUTES.hostedPaymentPage}
            render={() => {
              return (
                <HostedPaymentPage
                  authToken={authToken}
                  textsKey={RESOURCES_KEYS.hostedPaymentPage}
                  isFormEditable={isFormEditable}
                  merchantId={merchantId}
                  onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}`)}
                />
              );
            }}
          />
          <Route
            exact
            path={ROUTES.networkTokenAndThreeDsManagement}
            render={() => {
              return (
                <NetworkTokenAndThreeDsManagementForm
                  authToken={authToken}
                  textsKey={RESOURCES_KEYS.networkTokenAndThreeDsManagement}
                  isFormEditable={isFormEditable}
                  merchantId={merchantId}
                  onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}`)}
                />
              );
            }}
          />
          <Route
            exact
            path={ROUTES.merchantHistory}
            render={() => {
              return (
                <MerchantHistoryTable
                  authToken={authToken}
                  textsKey={RESOURCES_KEYS.merchantHistoryKeys}
                  isFormEditable={isFormEditable}
                  merchantId={merchantId}
                  onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}`)}
                />
              );
            }}
          />
          <Route
            exact
            path={ROUTES.merchantHistoryDetails}
            render={() => {
              return (
                <MerchantHistoryPage
                  authToken={authToken}
                  textsKey={RESOURCES_KEYS.merchantHistorDetailsyKeys}
                  isFormEditable={isFormEditable}
                  merchantId={merchantId}
                  merchantData={merchantData}
                  onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}/history`)}
                />
              );
            }}
          />
          <Route
            exact
            path={ROUTES.merchantNotifications}
            render={() => {
              return (
                <NotificationsPage
                  authToken={authToken}
                  textsKey={RESOURCES_KEYS.merchantNotifications}
                  isFormEditable={isFormEditable}
                  merchantId={merchantId}
                  merchantData={merchantData}
                  onBack={() =>
                    this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}/${ROUTE_KEYS.notificationSettings}`)
                  }
                />
              );
            }}
          />
          <Route
            exact
            path={ROUTES.notificationRetries}
            render={() => {
              return (
                <NotificationRetries
                  authToken={authToken}
                  textsKey={RESOURCES_KEYS.notificationRetries}
                  isFormEditable={isFormEditable}
                  merchantId={merchantId}
                  onBack={() =>
                    this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}/${ROUTE_KEYS.notificationSettings}`)
                  }
                />
              );
            }}
          />
          <Route
            exact
            path={ROUTES.achSettings}
            render={() => {
              return (
                <>
                  <AchSettingsForm
                    authToken={authToken}
                    textsKey={RESOURCES_KEYS.merchants}
                    isFormEditable={isFormEditable}
                    merchantId={merchantId}
                    merchantData={{
                      ...merchantData,
                    }}
                    onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}`)}
                    updateData={this.updateMerchantData}
                  />
                </>
              );
            }}
          />
          <Route
            exact
            path={ROUTES.widgetConfiguration}
            render={() => {
              return (
                <>
                  <WidgetConfigurationForm
                    authToken={authToken}
                    textsKey={RESOURCES_KEYS.widgetConfiguration}
                    isFormEditable={isFormEditable}
                    merchantId={merchantId}
                    merchantData={{
                      ...merchantData,
                    }}
                    onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchants}/${merchantId}`)}
                    updateData={this.updateMerchantData}
                  />
                </>
              );
            }}
          />
          <Route
            exact
            path={ROUTES.createOrEditNotificationRetries}
            render={() => {
              return (
                <NotificationRetriesEditForm
                  authToken={authToken}
                  textsKey={RESOURCES_KEYS.notificationRetries}
                  isFormEditable={isFormEditable}
                  merchantId={merchantId}
                  onBack={() =>
                    this.handleGoBack(
                      `/${RESOURCES_KEYS.merchants}/${merchantId}/${ROUTE_KEYS.notificationSettings}/${ROUTE_KEYS.notificationRetries}`,
                    )
                  }
                />
              );
            }}
          />
          <Route exact path="*" render={() => NotFoundResource()} />
        </Switch>
      </>
    );
  }
}

export default connect()(withRouter(withAuth(MerchantPage, ROUTES.merchants)));

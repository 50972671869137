import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Users from './users/Users';
import ReferenceData from './referenceData/ReferenceData';
import CorsDomains from './corsDomains/CorsDomains';
import { getConfigSection } from '../common/utils';
import Home from './Home';
import AuthCallback from '../auth/AuthCallback';
import DefaultLayout from './layouts/DefaultLayout';
import Logout from './Logout';
import MerchantsTable from './merchants/MerchantsTable';
import MerchantPage from './merchants/MerchantPage';
import MerchantGroupsPage from './merchantGroups/MerchantGroupsPage';
import MerchantGroupListTable from './merchantGroups/MerchantGroupListTable';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import { RESOURCES_KEYS, ROUTES } from '../common/constants';
import ScreenHeader from './common-components/ScreenHeader';
import { NotFoundResource } from './common-components/NotFoundResource';
import config from '../resources/config.json';
import ScrollToTop from './ScrollToTop';

class MainContainer extends React.PureComponent {
  render() {
    const linksConfig = getConfigSection(config, 'ui.mainContainer.menu');
    const { isLoading } = this.props;

    return (
      <div>
        <ScrollToTop />
        <Switch>
          <Route exact path="/auth/callback" render={routeProps => <AuthCallback {...routeProps} />} />
          <Route
            exact
            path={linksConfig.home.link}
            render={routeProps => (
              <DefaultLayout {...routeProps}>
                <Home {...routeProps} />
              </DefaultLayout>
            )}
          />
          <Route
            path={linksConfig.referenceData.link}
            render={routeProps => (
              <DefaultLayout {...routeProps}>
                <ReferenceData {...routeProps} />
              </DefaultLayout>
            )}
          />
          <Route
            exact
            path={linksConfig.corsDomains.link}
            render={routeProps => (
              <DefaultLayout {...routeProps}>
                <CorsDomains {...routeProps} />
              </DefaultLayout>
            )}
          />
          <Route
            exact
            path={ROUTES.merchants}
            render={routeProps => (
              <DefaultLayout {...routeProps}>
                <ScreenHeader textsKey={RESOURCES_KEYS.merchants} />
                <Breadcrumbs />
                <MerchantsTable {...routeProps} />
              </DefaultLayout>
            )}
          />
          <Route
            path={ROUTES.merchantPage}
            render={routeProps => (
              <DefaultLayout {...routeProps}>
                <ScreenHeader textsKey={RESOURCES_KEYS.merchants} />
                {isLoading || <Breadcrumbs />}
                <MerchantPage {...routeProps} />
              </DefaultLayout>
            )}
          />
          <Route
            exact
            path={ROUTES.merchantGroups}
            render={routeProps => (
              <DefaultLayout {...routeProps}>
                <ScreenHeader textsKey={RESOURCES_KEYS.merchantGroups} />
                <Breadcrumbs />
                <MerchantGroupListTable {...routeProps} />
              </DefaultLayout>
            )}
          />
          <Route
            path={ROUTES.merchantGroupPage}
            render={routeProps => (
              <DefaultLayout {...routeProps}>
                <ScreenHeader textsKey={RESOURCES_KEYS.merchantGroupsDetails} />
                {isLoading || <Breadcrumbs />}
                <MerchantGroupsPage {...routeProps} />
              </DefaultLayout>
            )}
          />
          <Route
            path={linksConfig.users.link}
            render={routeProps => (
              <DefaultLayout {...routeProps}>
                <Users {...routeProps} />
              </DefaultLayout>
            )}
          />
          <Route
            exact
            path={linksConfig.logout.link}
            render={routeProps => (
              <DefaultLayout {...routeProps}>
                <Logout {...routeProps} />
              </DefaultLayout>
            )}
          />
          <Route
            exact
            path="*"
            render={routeProps => <DefaultLayout {...routeProps}>{NotFoundResource()}</DefaultLayout>}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { isLoading } = state.breadCrumbs;
  return { isLoading };
};

export default connect(mapStateToProps)(MainContainer);

import React from 'react';
import { connect } from 'react-redux';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import CommonTable from '../../common-components/CommonTable';
import { getConfigSection } from '../../../common/utils';
import commonPropTypes from '../../../common/common-prop-types';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { ModalError } from '../../common-components/ModalWindow';

const uiTexts = require('../../../resources/uiTexts.json');
//const errorTexts = require('../../../resources/errorTexts.json');

class CorsDomainsPage extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadCorsDomains = this.loadCorsDomains.bind(this);
    this.addNewitem = this.addNewitem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      urls: [],
      successMessage: false,
      error: false,
      errorMessage: '',
    };
  }

  render() {
    const { authToken, navigationFunction, textsKey, onBack, isFormEditable } = this.props;
    const thisTextKey = `${textsKey}.domainGroups`;

    return (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        {this.state.error && this.renderErrorDialog()}
        <CommonTable
          authToken={authToken}
          textsKey={thisTextKey}
          dataFunctions={{
            loadData: this.loadCorsDomains,
            addItem: this.addNewitem,
            deleteItem: this.deleteItem,
          }}
          canClickOnItem={false}
          canAddInlineItem={true}
          onBack={onBack}
          onSave={this.handleOnSave}
          interceptors={{
            onSave: data => {
              if (
                data.find(d => {
                  const existingEntry = d.hasOwnProperty('urls');
                  const value = existingEntry ? d.urls : d.value;
                  if (!value) {
                    return false;
                  }
                  return value.includes('localhost');
                })
              ) {
                this.showLocalhostDomainWarning();
                return false;
              }
              return true;
            },
          }}
          isFormEditable={isFormEditable}
          navigationFunction={navigationFunction}
          hasBackButton={true}
          hasSaveButton={true}
          canFilterItems={false}
          testIdPrefix="merchant-cors-domains-table"
        />
      </>
    );
  }

  showLocalhostDomainWarning() {
    const errorMessage = getConfigSection(uiTexts, 'merchants.corsDomains.localhostDomainErrorMessage');

    this.setState({
      ...this.state,
      error: true,
      errorMessage,
    });
  }

  async loadCorsDomains() {
    const { merchantId } = this.props;
    const { urls } = await this.configurationDataApi.getMerchantCorsDomains(merchantId);

    const createArrayWithUrls = urls.split(', ');
    const response = createArrayWithUrls.filter(urls => urls !== '').map(urls => ({ urls }));

    this.setState({
      urls: response,
    });

    return response;
  }

  async deleteItem(item) {
    const { merchantId } = this.props;
    const corsDomains = item.isEdited
      ? this.state.urls.filter(data => !data.isEdited)
      : this.state.urls.filter(data => data.urls !== item.urls);
    const domains = corsDomains.map(data => data.urls).toString();
    const response = await this.configurationDataApi.updateCorsDomains(merchantId, domains);

    this.setState({
      urls: corsDomains,
      successMessage: true,
      error: false,
    });

    return response;
  }

  async addNewitem(newItem) {
    const newURL = newItem.urls;
    if (!this.validateUrl(newURL)) {
      return this.state.urls;
    }

    const { merchantId } = this.props;
    const updatedCorsDomains = [...this.state.urls, { urls: newURL }]
      .filter(domain => !domain.isEdited && domain.urls)
      .map(item => item.urls)
      .toString();
    const response = await this.configurationDataApi.updateCorsDomains(merchantId, updatedCorsDomains);
    const corsDomains = response.urls
      .split(', ')
      .filter(urls => urls !== '')
      .map(urls => ({ urls }));

    this.setState({
      urls: corsDomains,
      successMessage: true,
      error: false,
    });

    return corsDomains;
  }

  handleOnSave(data) {
    return data.find(domain => domain.isEdited);
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }

  renderErrorDialog() {
    const { errorMessage } = this.state;
    return (
      <ModalError
        errorKey={errorMessage}
        onConfirm={() => {
          this.setState({
            errorMessage: '',
            error: false,
          });
        }}
      />
    );
  }

  // TODO: SPD-1984 Waiting for a new requirements and validations will be a breack change for release 5.3
  validateUrl(newItem) {

    /*
    if (newItem === '') {
      this.setState({
        successMessage: false,
        error: true,
        errorMessage: getConfigSection(errorTexts, 'validation.mandatoryField'),
      });

      return false;
    }
    */

    const doesDomainExists = this.state.urls.find(corsDomain => !corsDomain.isEdited && corsDomain.urls === newItem);
    if (doesDomainExists) {
      this.setState({
        successMessage: false,
        error: true,
        errorMessage: getConfigSection(uiTexts, 'merchants.corsDomains.errorMessage'),
      });

      return false;
    }

    /*
    if (!this.isValidUrl(newItem)) {
      this.setState({
        successMessage: false,
        error: true,
        errorMessage: getConfigSection(errorTexts, 'validation.url'),
      });

      return false;
    }
    */

    return true;
  }

  isValidUrl(string) {
    const urlRegex = /^https?:\/\/((\d{1,3}\.){3}\d{1,3}|([a-z\d\-]+(\.[a-z\d\-]+)+))(:\d{2,5})?(\/[^\s]*)?(\?[^\s#]*)?(#[^\s]*)?(:\d{2,5})?$/i;
    
    return urlRegex.test(string);
  }
}

export default connect()(withRouter(CorsDomainsPage));
